import { Transition } from '@headlessui/react'
import cn from 'clsx'
import { useForm } from 'react-hook-form'
import { graphql } from 'gatsby'

import Button from '@components/common/Form/Button/Button'
import Input from '@components/common/Form/Input/Input'
import { useCopy } from '@hooks/useCopy'

import MailIcon from '@svg/mail.svg'

const EMAIL_REGEXP =
	/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i

const Newsletter = ({ onSubmit, title, theme, noPadding = false }) => {
	const { t } = useCopy()

	const currentTitle = title ? title : t('newsletter.title')
	const currentTheme = theme ? theme : 'light'

	const {
		register,
		handleSubmit,
		setError,
		formState: { isSubmitting, isSubmitSuccessful, isLoading, errors },
	} = useForm()

	const onSubmitHandler = async (data) => {
		if (onSubmit) {
			onSubmit(data)
		}
		try {
			const options = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					fields: [
						{
							name: 'email',
							value: data.email,
						},
					],
				}),
			}

			const response = await fetch(
				`https://api.hsforms.com/submissions/v3/integration/submit/44595267/a88aee40-c7ee-47cd-aeb0-2089c53ac452`,
				options
			)

			if (response.ok) {
				const result = await response.json()
				console.log('Form submitted successfully:', result)
			} else {
				const errorData = await response.json()
				setError('root.serverError', {
					type: 'server',
					message: errorData.message || 'Form submission failed',
				})
				console.error('Form submission error:', errorData)
			}
		} catch (err) {
			setError('root.serverError', { type: 'unknown', message: err.message })
			console.error('Unexpected error during form submission:', err)
		}
	}

	return (
		<div
			className={cn(
				'module-newsletter',
				'text-center tablet:text-left',
				{ 'grid-default ': currentTheme === 'dark' },
				{ 'bg-noise py-16 tablet:py-32': currentTheme === 'light' },
				{ 'px-16 tablet:px-32': !noPadding && currentTheme === 'light' }
			)}
		>
			<div
				className={cn(
					'flex flex-col rounded-16 desktop:flex-row  desktop:items-center desktop:justify-center ',
					{ 'container-border mt-32 bg-midnight pb-64 text-white desktop:py-64': currentTheme === 'dark' },
					{ 'newsletter-light-theme mt-0 pb-64 text-bodyColor desktop:py-32': currentTheme === 'light' }
				)}
				data-animation="appearY"
			>
				<h4
					className={cn(
						'mt-64 flex-grow text-center uppercase mobile:px-32 desktop:mt-0 desktop:w-50% desktop:max-w-75% desktop:whitespace-pre-line desktop:pl-32 desktop:text-left ',
						{ 'ts-48 mobile:mb-24 tablet:mb-24 desktop:mb-0': currentTheme === 'light' },
						{ 'ts-4 text-6xl desktop:text-9xl': currentTheme === 'dark' }
					)}
				>
					{currentTitle}
				</h4>
				<p
					className={cn(
						'ts-body mt-32 px-32 pb-0 mobile:text-left tablet:text-center desktop:mt-0 desktop:text-left',
						{ hidden: currentTheme === 'light' },
						{ 'mobile:mb-32 tablet:mb-32 desktop:mb-0 desktop:hidden': currentTheme === 'dark' }
					)}
				>
					{t('newsletter.text')}
				</p>
				<div className="relative flex items-center justify-center px-32 desktop:h-full desktop:w-50% desktop:justify-start">
					<Transition
						show={isSubmitSuccessful}
						className={cn(
							'absolute bottom-0 left-32 right-32 top-0 flex flex-col justify-center text-center desktop:bottom-0 desktop:top-0 desktop:px-48 ',
							{ 'gap-y-12': currentTheme === 'light' },
							{
								'gap-y-12 rounded-16 border-1 border-lightGray desktop:gap-y-24 desktop:py-56': currentTheme === 'dark',
							}
						)}
						enter="transition-opacity ease-immgPosIn duration-300 delay-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="transition-opacity ease-immgPosOut duration-300"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div
							className={cn(
								{ 'ts-newsletter-submit-title-light': currentTheme === 'light' },
								{ 'ts-newsletter-submit-title': currentTheme === 'dark' }
							)}
						>
							{t('newsletter.submit.title')}
						</div>
						<div className="ts-newsletter-submit-description text-orange">{t('newsletter.submit.description')}</div>
					</Transition>
					<div
						className={cn(
							'flex flex-col transition-opacity duration-300 ease-immgPosIn mobile:w-full mobile:items-start tablet:w-full desktop:w-auto',
							{
								'opacity-0': isSubmitSuccessful,
							}
						)}
					>
						<p
							className={cn(
								'ts-body mt-32 mobile:text-left tablet:text-center desktop:mt-0 desktop:text-left',
								{ hidden: currentTheme === 'light' },
								{ 'mobile:hidden  tablet:hidden desktop:block': currentTheme === 'dark' }
							)}
						>
							{t('newsletter.text')}
						</p>
						<form
							className={cn(
								'flex flex-col gap-y-24 mobile:w-full desktop:flex-row',
								{ 'mt-0': currentTheme === 'light' },
								{ 'mt-32': currentTheme === 'dark' }
							)}
							onSubmit={handleSubmit(onSubmitHandler)}
						>
							<Input
								color="white"
								className={cn('mb-0 w-full')}
								placeholder={t('newsletter.placeholder')}
								shape="left"
								hasShadow={currentTheme === 'light'}
								error={errors.email}
								inputClassName="mobile:text-center mobile:italic"
								type="email"
								required
								{...register('email', {
									required: t('form.error.required', { field: t('form.field.email') }),
									pattern: { value: EMAIL_REGEXP, message: t('form.error.email.pattern') },
								})}
								icon={MailIcon}
							/>
							<Button
								type="submit"
								loading={isSubmitting || isLoading}
								color="orange"
								className="w-full"
								classNameContentDiv="mobile:w-full tablet:w-full desktop:w-auto"
								shape="middle"
								classNameContent="mobile:flex-grow desktop:flex-grow mobile:w-full"
							>
								{t('newsletter.cta')}
							</Button>
						</form>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Newsletter

export const query = graphql`
	fragment NewsletterFragment on ContentfulSectionNewsletter {
		id: contentful_id
		type: __typename
		title
	}
`
